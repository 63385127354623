<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/home" class="back-button">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary"><h2 class="mb-0 ion-text-center">Cerebra</h2></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true" class="bg-white text-black">
      <div class="ion-padding horizontally-centered ion-margin-bottom wp-content">
        <h3>What Services do Cerebra provide?</h3>
        <p>
          Cerebra has a unique <strong>sleep advice service</strong> specialised in supporting
          families living in the UK who have a child with a neurodevelopmental condition.
        </p>
        <p>
          <a
            href="https://cerebra.org.uk/get-advice-support/sleep-advice-service/"
            target="_blank"
            rel="noreferrer noopener"
            ><strong>Tap here for further information.</strong></a
          >
        </p>
        <p>
          In addition, Cerebra offer <strong>online sleep seminars</strong> to parents and carers
          offering knowledge and information on sleep in children with brain conditions. Please note
          it is essential to book onto these seminars.
        </p>
        <p>
          <a
            href="https://cerebra.org.uk/get-advice-support/sleep-advice-service/sleep-seminars/"
            target="_blank"
            rel="noreferrer noopener"
            ><strong>Tap here to book an online seminar.</strong></a
          >
        </p>
        <p>To contact the Cerebra sleep service please tap below:</p>
        <p>
          <a
            href="https://cerebra.org.uk/get-advice-support/sleep-advice-service/contact-the-sleep-team/"
            target="_blank"
            rel="noreferrer noopener"
            ><strong>Tap here to contact us via the website.</strong></a
          >
        </p>
        <p>
          Or email: <a href="mailto:sleep@cerebra.org.uk"><strong>sleep@cerebra.org.uk</strong></a>
        </p>
        <img
          style="display: block; max-width: 300px; margin: 0 0 0 auto"
          src="/assets/cerebra.svg"
          alt=""
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'Bye',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent
  },
  setup(): any {
    return {
      chevronBackOutline
    };
  }
};
</script>
